import type { $Fetch } from 'nitropack'

import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useRouteStore } from '~/store/routesStore'
import { getCustomerSettings } from '~/utils/customerSettings'
import { getI18nLanguageId } from '~/utils/languages'
import { normalizeUrlInLanguages } from '~/utils/url'

const getModifiedOptions = (options: Record<string, unknown>): Record<string, never> => {
  const { currentCountry, currentCurrency, currentLanguage, userPreferences } = storeToRefs(useNewSettingsStore())

  const country = currentCountry.value?.Id || userPreferences.value?.CountryId
  const currency = currentCurrency.value?.Id || userPreferences.value?.CurrencyId
  const language = currentLanguage.value?.Id || getI18nLanguageId()

  let storageFilters = {}
  try {
    let _77dShopQuery = ''
    if (sessionStorage['77dShopQuery'].length <= 2) {
      sessionStorage.setItem('77dShopQuery', JSON.stringify({ 'discount-code': localStorage.getItem('addDiscount') }))
      _77dShopQuery = JSON.stringify({ 'discount-code': localStorage.getItem('addDiscount') })
    } else {
      _77dShopQuery = sessionStorage['77dShopQuery']
    }
    if (_77dShopQuery) {
      storageFilters = JSON.parse(_77dShopQuery)
    }
  } catch (e) {}

  if (options?.method === 'POST')
    return {
      ...options,
      body: {
        ...options.body,
        // TODD: drop once all APIs will use only UserPreference
        CountryId: country,
        CurrencyId: currency,
        LanguageId: language,
        UserPreference: {
          CountryId: country,
          CurrencyId: currency,
          LanguageId: language,
          DiscountCode:
            options['discount-code'] || storageFilters['discount-code'] || getCustomerSettings().DiscountCode,
        },
        DiscountCode: options['discount-code'] || storageFilters['discount-code'] || getCustomerSettings().DiscountCode,
        DiamondsInBag: getCustomerSettings().Shop.DiamondsInBag || [],
        ExpressItemsInBag: getCustomerSettings().Express.ItemsInBag || [],
        EngravingsInBag: getCustomerSettings().EngravingsInBag || 0,
        Url: window?.location.href,
      },
    }
  else if (options?.method === 'GET' || !options?.method) {
    return {
      ...options,
      params: {
        ...options.params,
        languageId: language,
        countryId: country,
        currencyId: currency,
      },
    }
  } else return options
}

// This function is used to save the UrlInLanguages in the store, will be used to change the route once language is changed
const saveResponseUrlInLanguages = ({ UrlInLanguages, config, PageUrl }, preventUrlChange) => {
  if (UrlInLanguages) useRouteStore().setUrlLanguages(normalizeUrlInLanguages(UrlInLanguages))

  // debugger
  if (!process.client) return

  if (!preventUrlChange && PageUrl) {
    const newUrl = PageUrl && PageUrl.replace(/.*\.77diamonds.*\.com/, '')
    history.replaceState(history.state, '', newUrl)
  }
}

// export const fetchFromProd = async (
//   url: string,
//   options?: Record<string, never>,
//   preventUrlChange = false,
// ): Promise<$Fetch> => {
//   // Timeout and retry are for dev since it take to long sometimes
//   const response = await $fetch(url, { ...getModifiedOptions(options), retry: 2 })
//   saveResponseUrlInLanguages(response, forceUrlInLanguagesSave)
//   return response
// }

export const fetchFromSoap = async (url: string, options?: Record<string, never>): Promise<$Fetch> => {
  const newUrls = '/api/webservice' + url
  return fetchFromNewApi(newUrls, getModifiedOptions(options))
}

import { useLoaderStore } from '~/store/loaderStore'
export const fetchFromNewApi = async (
  url: string,
  options?: Record<string, unknown>,
  preventUrlChange = false,
): Promise<$Fetch> => {
  const { start: startLoading, end: endLoading } = useLoaderStore()

  try {
    startLoading()
    const response = await $fetch(url, getModifiedOptions(options || {}))
    saveResponseUrlInLanguages(response, options?.preventUrlChange)
    return response
  } finally {
    endLoading()
  }
  // startLoading()
  // const response = await $fetch(`${url}`, getModifiedOptions(options || {}))
  // saveResponseUrlInLanguages(response, options?.preventUrlChange)
  // endLoading()
  // return response
}
