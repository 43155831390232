<template>
  <NuxtLayout v-if="!pending">
    <div>
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useNewSettingsStore } from '~/store/newSettingsStore'
// import { useBreadcrumbsStore } from '~/store/breadcrumbsStore'
import { useRouteStore } from '~/store/routesStore'
import { useOnlineAppointments } from '~/store/bookAppointmentStore'

const { getUserPreferences, getUserDiamondPreferences } = useUser()
const { getBag } = useShoppingBagStore()
const {
  setCountries,
  setCurrencies,
  setCurrentCurrency,
  setCurrentCountry,
  setLanguages,
  setCurrentLanguage,
  setPhoneNumbers,
  setShopSettings,
  setCompany,
} = useNewSettingsStore()
// const { fetchBreadcrumbs } = useBreadcrumbsStore()
const { initEnStaticRoutes } = useRouteStore()
const { getShowrooms } = useOnlineAppointments()
const { locale } = useI18n()

const initData = async () => {
  await Promise.all([getUserPreferences(), setCountries(), setCurrencies(), setShopSettings()])
  setCurrentCurrency()
  setCurrentCountry()
  setCompany()

  await setLanguages()
  setCurrentLanguage()
  await getUserDiamondPreferences()

  return true
}

const initLazyData = async () => {
  await Promise.all([
    // fetchBreadcrumbs(),
    getBag(),
    setPhoneNumbers(),
    getShowrooms(),
  ])
}
const { pending } = await useAsyncData('user-preferences', initData)

const dir = computed(() => (locale.value === 'ar' ? 'rtl' : 'ltr'))
watch(
  dir,
  (newDir) => {
    useHead({
      htmlAttrs: {
        dir: newDir,
      },
    })
  },
  { immediate: true },
)
onMounted(async () => {
  await initLazyData()
  nextTick(() => {
    initShoppingBagLocalStorageListener()
    initEnStaticRoutes()
  })
})
</script>
<style></style>
