import validate from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorization_45global from "/src/middleware/authorization.global.ts";
import i18n_45global from "/src/middleware/i18n.global.js";
import manifest_45route_45rule from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorization_45global,
  i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}