import { defineStore } from 'pinia'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { OmetriaHelper } from '~/utils/ometria'

//ToDo: google analytics
const gglTrack = {
  UpdateCountry: () => {},
  UpdateCurrency: () => {},
  BasketCheckoutPageView: () => {},
}

export const useShoppingBagStore = defineStore('shoppingBag', () => {
  const { currentCountry, currentCurrency, currentLanguage } = storeToRefs(useNewSettingsStore())
  const { getBasket, removeFromBasket, updateRingSize, updateEngraving, applyDiscount } = useBasket()
  const { getUser: getMe } = useUser()

  const Items = ref(null)
  const OrderPrice = ref(null)
  const IncludeVat = ref(null)
  const discountCode = ref(null)
  const discountError = ref(false)
  const loggedUser = ref(null)

  const setBag = (bagContent) => {
    Items.value = bagContent.Items
    OrderPrice.value = bagContent.OrderPrice
    IncludeVat.value = bagContent.IncludeVat

    // TODO: update code below after this is done https://77diamonds.atlassian.net/browse/WV-1543
    try {
      OmetriaHelper.bulkAddItem(bagContent.Items)
      OmetriaHelper.setTotal(bagContent.OrderPrice.TotalPrice.FinalPrice.NumericPrice.WithVat, bagContent.CurrencyCode)
      OmetriaHelper.set()
    } catch (e) {
      // Handle error
    }

    gglTrack?.UpdateCountry?.(bagContent.PageViewTracking.Country)
    gglTrack?.UpdateCurrency?.(bagContent.PageViewTracking.Currency)
    gglTrack?.BasketCheckoutPageView?.(
      bagContent.PageViewTracking.Category,
      bagContent.PageViewTracking.SubCategory,
      bagContent.PageViewTracking.Name,
      bagContent.PageViewTracking.Price,
      bagContent.OrderPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
    )
  }

  const setDiscountCode = (code) => {
    discountCode.value = code
  }

  const setUser = (isLoggedIn) => {
    loggedUser.value = isLoggedIn
  }

  const getBag = async (code) => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: code,
      },
    }
    const result = await getBasket(payload)
    setBag(result)
  }

  const removeItem = async (uniqueCode) => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
      },
      uniqueCode,
    }
    const result = await removeFromBasket(payload)
    if (Items.value && Items.value.length > 0) {
      const removedItem = Items.value.filter((item) => item.BagUniqueCode == uniqueCode)
      window.DataLayer?.productCartObject('removeFromCart', removedItem)
    }
    setBag(result)
  }

  const updateBagRingSize = async ({ uniqueCode, ringSizeId, ringSizeIndex, ringSizeName }) => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: '',
      },
      uniqueCode,
      ringSizeId,
      ringSizeIndex,
      ringSizeName,
    }
    const result = await updateRingSize(payload)
    setBag(result)
  }

  const editEngraving = async ({ uniqueCode, fontOptionId, description }) => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: '',
      },
      editEngraving: { uniqueCode, fontOptionId, description },
    }

    const result = await updateEngraving(payload)
    setBag(result)
  }

  const getUser = async () => {
    try {
      const res = await getMe()
      setUser(res)
    } catch (err) {
      setUser(false)
    }
  }

  const applyDiscountCode = async (code) => {
    const payload = {
      countryId: currentCountry.value?.Id,
      currencyId: currentCurrency.value?.Id,
      languageId: currentLanguage.value?.Id,
      discountCode: code,
    }
    try {
      const res = await applyDiscount(payload)
      setUser(res)
    } catch (err) {
      discountError.value = true
      setUser(false)
    }
  }

  const itemsCount = computed(() => Items.value?.length)

  return {
    Items,
    OrderPrice,
    IncludeVat,
    discountCode,
    discountError,
    loggedUser,
    getBag,
    removeItem,
    updateBagRingSize,
    editEngraving,
    applyDiscountCode,
    getUser,
    setBag,
    setDiscountCode,
    setUser,
    itemsCount,
  }
})
