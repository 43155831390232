import { defineStore } from 'pinia'
import * as api from '@/api/shop.js'

import { isPairDiamond } from '@/utils/shop/shopUtils.js'
// TODO: add Swal or its alternative
// import Swal from 'sweetalert2';
// const Swal = () => import(/* webpackChunkName: "shop" */ 'sweetalert2')
// import VueSimpleAlert from 'vue-simple-alert'

// TODO: use alternative to VueSimpleAlert
const VueSimpleAlert = {
  fire: (arr) => {
    console.log('VueSimpleAlert', arr)
    return { value: true }
  },
}

import { getGemShapeGroup, getShape } from '~/utils/shop/gemShapes.js'

import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useShoppingBagStore } from '~/store/shoppingBagStore'

import expressSettings from '~/constants/expressSettings'

const { addToBasket, addDiamondToBasket } = useBasket()

const getDefaultState = () => ({
  all: [],
  totalItems: 0,
  category: {},
  filtersOptions: null,
  item: {},
  selectedItem: null,
  itemIsDefault: true,
  diamonds: [],
  loadingDiamonds: false,
  diamondListLoaded: false,
  selectedDiamonds: [],
  selectedDiamondsIsDefault: null,
  totalDiamonds: 0,
  currentCarat: null,
  v12FinanceOptions: null,
  addingItemToShoppingBag: false,
  vat: null,
  financeOptions: null,
})

export const useProductsStore = defineStore('products', {
  state: () => getDefaultState(),

  actions: {
    setItem([item, isDefault = false]) {
      this.item = item || {}
      this.itemIsDefault = isDefault || !item
    },
    setDiamonds(diamonds) {
      this.diamonds = diamonds.Diamonds
      this.totalDiamonds = diamonds.Total
    },
    updateSelectedDiamonds([newDiamonds, isDefault = false]) {
      if (newDiamonds?.length) {
        const urlSearchParams = new URLSearchParams(window.location.search)

        const diamondsToKeep = this.selectedDiamonds.filter(
          (diamond) =>
            urlSearchParams.get('diamond')?.includes(diamond.Code) ||
            urlSearchParams.get('opened')?.includes(diamond.Code),
        )

        this.selectedDiamonds = [...diamondsToKeep, ...newDiamonds]
      }

      this.selectedDiamondsIsDefault = isDefault
    },
    async getCategory(category) {
      this.category = await api.getCategory(category)
    },
    async getCategoryOptions(opts) {
      const products = await api.getCategoryOptions(opts)
      // const products = _all

      this.filtersOptions = products.FilterOptions
      this.filtersOptions.currentCategory = opts.category

      await this.setProducts([products.Items, opts.query])
      this.totalItems = products.TotalItems

      return true
    },
    async setProducts([products, query]) {
      this.all = products

      const item = products[0]

      // const route = useRoute()
      // const isItemDetails = route.query.item && route.query.item > 0

      if (!query.isExpressShop && item && this.itemIsDefault) {
        // set default item
        this.getItem({
          ...query,
          category: item.Category,
          subcategory: item.SubCategory,
          item: item.Id,
          query: {
            ...query,
            shape: item.DiamondShape,
            isDefault: true,
            preventUrlChange: true,
          },
        }).catch((e) => {
          console.error(e)
        })
      }
    },
    async getItem({ category, subcategory, item, query, forceBandSettings }) {
      // return _item
      const response = await api.getItem({ category, subcategory, item, query, forceBandSettings })
      const nuxtApp = useNuxtApp()
      const { t } = nuxtApp.$i18n
      // TODO: drop this `response.Status &&` later, BE guys changed API
      if (response.Status && response.Status != expressSettings.Status.SUCCESS) {
        switch (response.Status) {
          case expressSettings.Status.ITEM_ALREADY_IN_BAG: {
            VueSimpleAlert.fire({
              title: t('you-ve-got-it', "You've got it!"),
              text: t(
                'item-already-in-bag-error',
                'It seems this item has already been added to your bag. Need help completing your order? An expert is currently available to chat.',
              ),
              // icon: 'error',
              confirmButtonText: t('chat-with-an-expert', 'Chat With An Expert'),
            })
            openChat2()
            break
          }
          case expressSettings.Status.DIAMOND_NOT_SUPPORTED: {
            const { $stoneType } = useShop()
            const result = VueSimpleAlert.fire({
              title: t('we-re-sorry', 'We’re sorry!'),
              // width: '50rem',
              buttonsStyling: false,
              customClass: {
                confirmButton: '_cta-black _mr-2',
                cancelButton: '_cta-black',
              },
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: t('change-setting', 'Change Setting'),
              cancelButtonText: t('change-stone', 'Change {stoneType}').replace('{stoneType}', $stoneType()),
              html: t(
                'stone-cannot-be-added',
                'It seems this {stoneType} shape cannot be added to your selected setting. To continue, please choose a different shape or setting. You can also <u>contact our bespoke team</u> to create your own design.',
              ).replace('{stoneType}', $stoneType()),
              // icon: 'error',
            })
            const router = useRouter()
            if (result.value) {
              const { getShopRouteByLegacyName } = useShopRoute()
              router.push(getShopRouteByLegacyName({ name: 'category' }))
            } else if (result.dismiss === Swal?.DismissReason.cancel) {
              const { $removeDiamondLink } = useShop()
              // not sure that it works on prod
              router.push($removeDiamondLink(true, query))
            }

            break
          }
          case expressSettings.Status.ERROR:
            break

          default: {
            VueSimpleAlert.fire({
              title: 'Error!',
              text: t('error-unknow'),
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          }
        }

        throw new Error(response.Status)
      } else {
        this.setItem([{ ...response, query }, query.isDefault])

        if (query.setSelectedItem || (!this.selectedItem && !this.itemIsDefault)) {
          this.selectedItem = item
        }

        const route = useRoute()
        let _route = { query: route.query }

        _route.query.subcategory = subcategory
        _route.query.item = response.ItemSettings.Id // init item if it is not set

        if (query.metal) {
          _route.query.metal = query.metal
        } else {
          _route.query.metal = response.ItemSettings.ComesInMetals[0].Id
        }

        if (!this.itemIsDefault) {
          if (query.stoneType && query.stoneType == '2') {
            _route.query.shape =
              getGemShapeGroup(query.shape || (query.shapes && query.shapes[0])).length > 0
                ? getGemShapeGroup(query.shape || (query.shapes && query.shapes[0]))[0].Id
                : query.shape
            _route.query.shapes =
              getGemShapeGroup(query.shape || (query.shapes && query.shapes[0])).length > 0
                ? getGemShapeGroup(query.shape || (query.shapes && query.shapes[0]))[0].Id
                : query.shape
          } else {
            _route.query.shape = query.shape || (query.shapes && query.shapes[0])
            _route.query.shapes = query.shape || query.shapes
          }
        }

        try {
          let diamonds = response.ItemSettings.OrderInfo.ItemPrice.DiamondsPrice
          if (!query.diamond && diamonds?.length) {
            await this.getSelectedDiamonds({
              diamond: diamonds.map((d) => d.UniqueCode).join(),
              isDefault: !query.diamond,
              shapes: _route.query.shapes ? _route.query.shapes : null,
              preventUrlChange: true,
              searchBlocked: query.isExpressShop,
            })
          }
        } catch (e) {
          throw new Error(e)
        }
      }

      // console.log('getItem', response)

      return response
    },

    async getAllProducts({ category, query, reset = false }) {
      try {
        if (reset) {
          this.all = null
        }
        const products = await api.getProducts({ category, query })

        if (!query.page || query.page == 1) {
          this.setProducts([products.Items, query])
        } else {
          this.all = [...this.all, ...products.Items]
        }
        this.totalItems = products.TotalItems
      } catch (e) {
        console.error(e)
      }
    },
    async resetCategory() {
      this.category = null
    },
    async removeItem() {
      this.item = {}
      this.itemIsDefault = true
    },
    async getFinanceOption() {
      const { currentCountry, currentCurrency } = useNewSettingsStore()
      const response = await api.getFinanceOption({
        amount: this.item.ItemSettings.OrderInfo.ItemPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
        countryId: currentCountry.Id,
        currencyId: currentCurrency.Id,
      })
      this.financeOptions = { ...response }
      return response
    },
    async financeCalculator() {
      const { getFinanceOptions } = useProducts()
      const amount = this.item.ItemSettings.OrderInfo.ItemPrice.TotalPrice.FinalPrice.NumericPrice.WithVat
      const response = await getFinanceOptions({
        amount,
      })
      this.v12FinanceOptions = { ...response }
    },

    async getVat() {
      const response = await api.getVat()
      this.vat = { ...response }
    },

    async getDiamonds({ category, subcategory, item, query, page = 1 }) {
      try {
        this.loadingDiamonds = true
        this.diamondListLoaded = true

        const diamonds = await api.getDiamonds({ category, subcategory, item, query, page })
        if (diamonds.Diamonds) {
          if (page == 1) {
            this.setDiamonds(diamonds)
            const isPair = isPairDiamond()
            if (diamonds.Diamonds && diamonds.Diamonds.length >= (isPair ? 2 : 1)) {
              let selectedDiamonds

              if (isPair) {
                selectedDiamonds = [diamonds.Diamonds[0], diamonds.Diamonds[1]]
              } else {
                selectedDiamonds = [diamonds.Diamonds[0]]
              }
              this.updateSelectedDiamonds([selectedDiamonds, query])
            } else {
              this.removeSelectedDiamonds()
            }
          } else {
            this.diamonds = [...this.diamonds, ...diamonds.Diamonds]
          }
        } else {
          return false
        }
        this.loadingDiamonds = false
      } catch (e) {
        console.error(e)
      }
    },
    removeSelectedDiamonds() {
      this.selectedDiamonds = []
      this.selectedDiamondsIsDefault = false
    },

    async setSelectedDiamonds({ selectedDiamonds, query }) {
      if (selectedDiamonds) {
        const sameDiamond = this.selectedDiamonds.every((d) => selectedDiamonds.some((sd) => sd.Code === d.Code))

        if (!sameDiamond || isPairDiamond()) {
          // Allow to set same diamonds for pair diamond, since it can change order, and we display the first diamond in the diamond details
          this.updateSelectedDiamonds([selectedDiamonds])
          const route = useRoute()

          route.query.shape = selectedDiamonds[0].ShapeId

          if (query.item > 0) {
            await this.getItem({
              ...query,
              query: {
                ...query,
                diamond: selectedDiamonds.map((d) => d.Code),
                preventUrlChange: true,
              },
            })
          }
        }
      }
    },

    async getSelectedDiamonds(query) {
      let _diamonds = query.diamond.split(',')

      if (this.selectedDiamonds.length) {
        // Do not need extra handling for more complex cases, because there are only matching pairs that can have constant pair of diamonds or single diamond
        const alreadySelectedDiamonds = this.selectedDiamonds.filter((d) => _diamonds.includes(d.Code))

        if (alreadySelectedDiamonds.length) {
          return alreadySelectedDiamonds
        }
      }

      if (this.diamonds.length) {
        let stateDiamonds = this.diamonds.filter((d) => _diamonds.includes(d.Code))

        if (stateDiamonds.length) {
          this.updateSelectedDiamonds([stateDiamonds, query.isDefault])
          return stateDiamonds
        }
      }

      let d = await api.getDiamondsByIds(query)

      let _diamondStoneType = d.Diamonds && d.Diamonds.length > 0 && d.Diamonds[0].StoneTypeID
      let queryStoneType = query.stoneType

      if (!queryStoneType) {
        queryStoneType = _diamondStoneType
      }

      if (d.Status == expressSettings.Status.SUCCESS && _diamondStoneType == queryStoneType) {
        this.updateSelectedDiamonds([d.Diamonds, query.isDefault])
        this.setDiamonds(d)
        return d.Diamonds
      } else {
        return false
      }
    },
    async addToShoppingBag({ category, subcategory, item, query, quick = false }) {
      const router = useRouter()
      const shoppingBagStore = useShoppingBagStore()
      // const { metal, shape } = query
      try {
        if (!this.addingItemToShoppingBag) {
          this.addingItemToShoppingBag = true
          await addToBasket({ category, subcategory, item, query })
          router.push('/shop-bag')

          //ToDo: we need business logic to understand whats going on here

          // if (status != expressSettings.Status.SUCCESS) {
          // } else {
          //   const item = this.item.ItemSettings
          //   const orderInfo = this.item.ItemSettings.OrderInfo.ItemPrice
          //   if (item && orderInfo) {
          //     ga4_productObject('addToCart', { ...item, Price: { Price: orderInfo } }, '')
          //     microsoftTagsPush('add_to_cart', {})
          //   }
          //
          //   gglTrack(
          //     'AddToBasketShop',
          //     translate(getCategory(category).label),
          //     translate(getSubCategory(subcategory).label),
          //     item.Name,
          //     translate(getMetalById(metal || 2).label),
          //     (shape && translate(getShape(shape).label)) || null,
          //     quick,
          //   )
          //
          //   ometriaTrackBasket()
          // }

          // let location = translate('[[/ShoppingBagPage.aspx]]')

          // if (query['discount-code']) {
          //   const sign = ~location.indexOf('?') ? '&' : '?'
          //   location += sign + 'discount=' + query['discount-code']
          // }

          // window.location = location
        }
      } catch (err) {
        alert(`Temporary error handling, ${err}`)
        window?.decibelTracking?.sendApplicationError('Error on AddToBasket call')
      } finally {
        this.addingItemToShoppingBag = false
      }
    },

    async addDiamondToShoppingBag({ category, subcategory, item, shape, diamonds, query }) {
      const router = useRouter()

      try {
        this.addingItemToShoppingBag = true
        await addDiamondToBasket({ category, subcategory, item, shape, diamonds, query })
        router.push('/shop-bag')

        /*if (status != expressSettings.Status.SUCCESS) {
                                                                                  switch (status) {
                                                                                    case expressSettings.Status.ITEM_ALREADY_IN_BAG:
                                                                                      // alert(translate('error-item-already-in-bag'));
                                                                                      //TODO: use/implement alternative to Swal.fire
                                                                                      // Swal.fire({
                                                                                      //   title: translate('you-ve-got-it', "You've got it!"),
                                                                                      //   text: translate(
                                                                                      //     'item-already-in-bag-error',
                                                                                      //     'It seems this item has already been added to your bag. Need help completing your order? An expert is currently available to chat.',
                                                                                      //   ),
                                                                                      //   // icon: 'error',
                                                                                      //   confirmButtonText: translate('chat-with-an-expert', 'Chat With An Expert'),
                                                                                      // }).then(openChat2)
                                                                                      break
                                                                                    case expressSettings.Status.ERROR:
                                                                                      alert(translate('error-unknow'))
                                                                                      break
                                                                                    default:
                                                                                  }
                                                                                } else {
                                                                                  window.location = translate('[[/ShoppingBagPage.aspx]]')
                                                                                }*/
      } catch (err) {
        alert(`Temporary error handling, ${err}`)
        window?.decibelTracking?.sendApplicationError('Error on AddToBasket call')
      } finally {
        this.addingItemToShoppingBag = false
      }
    },
    async getReviews({ item, page, sort }) {
      const reviews = await api.getReviews({
        item,
        reviewsPage: page,
        sort,
      })
      if (page === 0) {
        this.item.ProductReviews.Reviews = reviews
      } else {
        this.item.ProductReviews.Reviews = [...this.item.ProductReviews.Reviews, ...reviews]
      }
    },

    setCurrentCarat(currentCarat) {
      this.currentCarat = currentCarat
    },

    resetState() {
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        this[key] = value
      })
    },

    resetDiamonds() {
      this.removeSelectedDiamonds()
      this.diamonds = []
      this.totalDiamonds = 0
    },
  },
})
