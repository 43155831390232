interface ProductConfig {}

type Product = {
  id: number
  name: string
}

import { useProductsStore } from '~/store/productsStore'

const useShopRouteStore = defineStore('shopRoute', {
  state: () => ({
    name: '',
    query: {},
    params: {},
  }),

  actions: {
    $isExpress() {
      return this.query.isExpressShop || this.query.stockNumber
    },

    setItem(product) {
      // console.log(product)
      const query = {
        ...this.query,
        item: product.Id,
      }

      delete query.variation

      if (product.Metal) {
        query.metal = product.Metal
      }

      if (this.$isExpress()) {
        query.selectedDiamonds = product.SelectedDiamonds

        if (product.AvailableRingSizes) {
          query.ringSize = (product.AvailableRingSizes.find((rs) => rs.Selected) || product.AvailableRingSizes[0]).Value
        }
        delete query.diamond
      } else if (this.query.diamond) {
        query['start-diamond'] = true
        query.diamond = this.query.diamond
      }

      if (product.DiamondShape) {
        query.shape = product.DiamondShape
      } else {
        query.shape = null
      }

      query.category = product.Category
      query.subcategory = product.SubCategory

      if (product.StockNumber) {
        query.stockNumber = product.StockNumber
      }

      if (product.SelectedDiamonds && product.SelectedDiamonds.length) {
        query.diamond = product.SelectedDiamonds.map((sd) => sd.Code).join(',')
      }

      this.push({
        name: this.$isExpress() ? 'item-finish' : 'item',
        query,
      })

      // if (this.expressShopping) {
      //   query.quickShipping = '1'
      // }

      // if (this.name == 'item-finish') {
      //   return {
      //     params: { ...this.params },
      //     query,
      //   }
      // } else {
      //   return {
      //     name: this.$isExpress() ? 'item-finish' : 'item',
      //     query,
      //   }
      // }
    },

    async set(name, query) {
      this.query = query

      await refreshNuxtData(name)

      this.name = name
    },

    async reset() {
      await refreshNuxtData(this.name)
    },

    remove() {
      this.query = {}
      this.params = {}
    },

    async push(route) {
      // debugger
      // console.log(route)

      if (route.query?.category && route.query.category != this.query.category)
        await useProductsStore().getCategory(route.query.category)

      if (route.query) this.query = route.query

      if (route.params) {
        this.params = route.params
      } else {
        await refreshNuxtData(route.name || this.name)
        this.params = {}
      }

      // if (this.name == route.name)
      // else
      if (route.name) this.name = route.name
      console.log(this.name + ' pushed')
      // history.pushState(history.state, '', this.query)
      return true
    },

    async replace(route) {
      // debugger
      // console.log(route)

      this.query = route.query
      this.params = {}

      // if (this.name == route.name)
      await refreshNuxtData(route.name || this.name)
      // else
      if (route.name) this.name = route.name
      // console.log(this.name + ' replaced')
      // history.replaceState(history.state, '', this.query)
    },
  },
})

export default useShopRouteStore
