export const PUBLIC_PAGES_ROUTES = {
  SHOWROOMS: '/showrooms',
  REGISTER: '/register',
}

export const PRIVATE_PAGES_ROUTES = {
  ADDRESS: '/account/addresses',
  ORDERS: '/account/orders',
  PRIVACY: '/account/privacy',
  PROFILE: '/account/profile',
}

export const AUTH_PAGES_ROUTES = {
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  LOGOUT: '/logout',
}

export const POST_PAYMENT_PAGES_ROUTES = {
  FAILED: '/failed.aspx',
  PAYPAL: '/paypal-return',
  SUCCESS: '/success.aspx',
}
